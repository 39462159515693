import { render, staticRenderFns } from "./view-guides-modal.vue?vue&type=template&id=1fdd3e1f&scoped=true&"
import script from "./view-guides-modal.vue?vue&type=script&lang=js&"
export * from "./view-guides-modal.vue?vue&type=script&lang=js&"
import style0 from "./view-guides-modal.vue?vue&type=style&index=0&id=1fdd3e1f&lang=scss&scoped=true&"
import style1 from "./view-guides-modal.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fdd3e1f",
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QIcon,QItem,QItemSection,QAvatar,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QIcon,QItem,QItemSection,QAvatar})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
