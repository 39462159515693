import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
// import IntegerField from '@/shared/fields/integer-field';
import JsonField from '@/shared/fields/json-field';
// import EnumeratorField from '@/shared/fields/enumerator-field';
// import StringArrayField from '@/shared/fields/string-array-field';
// import BooleanField from '@/shared/fields/boolean-field';
// import IntegerRangeField from '@/shared/fields/integer-range-field';
// import ImagesField from '@/shared/fields/images-field';

// import i18n from './vueI18n'

function label(name) {
  return i18n(`entities.branch.fields.${name}`);
}
// function enumeratorLabel(name, value) {
//   return i18n(`entities.branch.enumerators.${name}.${value}`);
// }

const fields = {
  id: new IdField('id', label('id')),
  name: new JsonField('name', label('name')),
  // branchNumber: new IntegerField('branchNumber', label('branchNumber'), {}),
  address: new JsonField('address', label('address')),
  cityId: new StringField('cityId', label('cityId'), {}),
  companyId: new StringField('companyId', label('companyId'), {}),
  phoneNumber: new StringField('phoneNumber', label('phoneNumber'), {}),
  // logo: new ImagesField('logo', label('logo'),
  //   '/saryahCompanu/logo/branch',
  //   { max: 1 },
  // ),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class BranchModel extends GenericModel {
  static get fields() {
    return fields;
  }
}