//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ViewTripModal from '@/modules/branch/components/view-trip-modal.vue';

import { mapGetters, mapActions } from 'vuex';
import { BranchPermissions } from '@/modules/branch/branch-permissions';
import { i18n, getLanguageCode } from '@/i18n';
import moment from 'moment';

import { BranchModel } from '@/modules/branch/branch-model';
// import { FormSchema } from '@/shared/form/form-schema';
const { fields } = BranchModel;

export default {
  name: 'app-trip-card',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  components: {
    [ViewTripModal.name]: ViewTripModal,
  },

  data() {
    return {
      language: getLanguageCode(),
      selectedID: null,
      dialogVisible: false,
      modalVisible: false,
      statusLoading: false,
      
      model: null,
      places: [],
      placesName: [],
      images: [],
      isDisableBranch: false,
    }
  },
  computed: {
    ...mapGetters({
      // record: 'branch/view/record',
      // loading: 'branch/view/loading',
      destroyLoading: 'branch/destroy/loading',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      changeStatusLoading: 'branch/form/changeStatusLoading',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
    hasPermissionToEdit() {
      return new BranchPermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new BranchPermissions(this.currentUser).destroy;
    },
    // displayPlaces() {
    //   return this.placesName.slice(0, 3);
    // },
    // displayImages() {
    //   return this.images.slice(0, 3);
    // },
    // displayRecord() {
    //   return {
    //     ...this.value,
    //     places: this.places,
    //     images: this.images,
    //   }
    // },
    // startPoint() {
    //   return this.value.startCity ? this.value.startCity.name[this.currentLanguageCode] : 'ــــ'
    // },
    // endPoint() {
    //   const lastPlaceIndex = this.placesName.length - 1
    //   const lastPlace = this.placesName[lastPlaceIndex]
    //   return lastPlace ? lastPlace[this.currentLanguageCode] : 'ــــ'
    // },
  },

  // mounted() {
  //   this.value.programs.forEach(program => {
  //     program.places.forEach(place => {
  //       if (place.selectedImage) this.images.push(place.selectedImage)
  //       if (place.place) this.placesName.push(place.place.placeName)
  //       if (place.place) this.places.push({ ...place.place, averageTime: place.averageTime })
  //     })
  //   });
  // },
  methods: {
    ...mapActions({
      doFind: 'branch/view/doFind',
      doDestroy: 'branch/destroy/doDestroy',
      doDisable: 'branch/form/doDisable',
      doEnable: 'branch/form/doEnable',
    }),
    i18n(code, args) {
      return i18n(code, args);
    },
    presenter(row, fieldName) {
      return BranchModel.presenter(row, fieldName);
    },
    presenterMap(row, fieldName) {
      const val = BranchModel.presenter(row, fieldName);
      return val[this.currentLanguageCode]
    },
    presenterDate(row, fieldName) {
      return moment(BranchModel.presenter(row, fieldName)).locale(this.currentLanguageCode).format("D MMM, YYYY"); 
    },
    presenterDay(row, fieldName) {
      return moment(BranchModel.presenter(row, fieldName)).locale(this.currentLanguageCode).format("dddd");
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = BranchModel.presenter(row, fieldName)
      let date =  `${now} ${time}`;
      return moment(date).locale(this.currentLanguageCode).format("hh:mm a"); 
    },
    onOpenModal() {
      this.modalVisible = true;
      this.dialogVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 200);
    },
  },
};
