//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { getLanguageCode } from '@/i18n';
import { i18n } from '@/i18n';

export default {
  name: 'app-view-trip-modal',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      language: getLanguageCode(),
      model: null,
    };
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    dialogVisible: {
      get: function () {
        return this.visible;
      },
      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },

  methods: {
    ...mapActions({
      
    }),
    i18n(key, args) {
      return i18n(key, args);
      // return this.$t(key, args);
    },
  },
};
