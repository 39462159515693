import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class BranchPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.branchRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.branchImport,
    );
    this.branchAutocomplete = permissionChecker.match(
      Permissions.values.branchAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.branchCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.branchEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.branchDestroy,
    );
  }
}
