//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ViewGuidesModal from '@/modules/branch/components/view-guides-modal.vue';
import TripCard from '@/modules/branch/components/trip-card.vue';
import GuideCard from '@/modules/branch/components/guide-card.vue';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/i18n';
import { BranchModel } from '@/modules/branch/branch-model';
const { fields } = BranchModel;

export default {
  name: 'app-branch-view-page',
  props: ['id'],

  components: {
    [ViewGuidesModal.name]: ViewGuidesModal,
    [TripCard.name]: TripCard,
    [GuideCard.name]: GuideCard,
  },

  // async created() {
  //   await this.doFind(this.id);
  // },

  data() {
    return {
      modalVisible: false,
      dialogVisible: false,
      status: null,
      model: null,
    };
  },
  computed: {
    ...mapGetters({
      record: 'branch/view/record',
      loading: 'branch/view/loading',
      rows : 'branch/view/rows',
      currentUser : 'auth/currentUser'
    }),
    isRTL() {
      return this.$rtl.isRTL;
    },
    fields() {
      return fields;
    },
  },
  async mounted(){
    await this.doFind({id:this.$route.params.id,companyId:this.currentUser.companyId})
    await this.doFetchBranchGuides(this.$route.params.id)
  },
  methods: {
    ...mapActions({
      doFind: 'branch/view/doFind',
      doFetchBranchGuides: 'branch/view/doFetchBranchGuides'
    }),
    presenterDate(val) {
      return val ? moment(val).locale('en').format("D MMM, YYYY") : 'ـــــــ'; 
    },
    presenterDateTime(val) {
      return val ? moment(val).locale('en').format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
    i18n(code) {
      return i18n(code);
    },
    presenter(record, fieldName) {
      return BranchModel.presenter(record, fieldName);
    },
    onOpenModal(status) {
      this.status = status;
      switch (status) {
        case 'all':
          break;
        case 'online':
          break;
        case 'offline':
          break;
        default:
          break;
      }
      this.modalVisible = true;
      this.dialogVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 200);
    },
  },
};
